import React from 'react';
import Card from "react-bootstrap/Card";

function contact() {
  return (
    <> 
<Card>
        <Card.Header><span style={{ fontWeight: 'bold' }}  >Contact Us</span></Card.Header>
        <Card.Body>
          <Card.Title>Seeking HELP for Health/Education/Matrimony?</Card.Title>
          <Card.Text>
          Please write to us at <span style={{ color: 'blue', fontWeight: 'bold', textDecoration: 'underline' }}  > help@helpin.in</span> with,<br />
           Your Name:<br />Your contact number :<br /> Your email ID :<br /> Your address :<br /> Your requirements :<br />
           <br /><br />
          <h5>To contribute as volenteers :</h5>
          Please write to us at <span style={{ color: 'blue', fontWeight: 'bold', textDecoration: 'underline' }}  > help@helpin.in</span> with,<br />
           Your Name:<br />Your contact number :<br /> Your email ID :<br /> Your ideas in detail :<br /> Hours per month you can contribute :<br />
           <br /><br />
          <h5>For partnering with us :</h5> 
          Please write to us at <span style={{ color: 'blue', fontWeight: 'bold', textDecoration: 'underline' }}  > help@helpin.in</span> with,<br />
           Your Organisation Name :<br /> Organisation Address :<br /> Contact Person Name :<br />Your contact number :<br /> Your email ID :<br /> Your ideas in details :<br /><br /><br />

           <span style={{ color: 'rgba(53,53,53,1))',  fontStyle: 'italic' }}  >Raise by Lifting others...</ span> Write to us for <span style={{ color: 'rgba(53,53,53,1))', fontWeight: 'bold' }}  >80G certificate for your TAX SAVING</span>.
          </Card.Text>
        </Card.Body>
      </Card>         
      </>
  );
}

export default contact;
