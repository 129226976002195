import React from "react";
import FirstComp from "../componet/firstComp";
import { Card, Container } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";


import secBackground from "../img/timage.png";
import marrImage from "../img/marrImage.png";
import healthcamp from "../img/healthcamp.png"

import "../App.css";


export default function froentPage() {
  return (
    <>
      <FirstComp></FirstComp>


        <Card
          className="text-center"
          border="0"
          style={{
            backgroundImage: `url(${secBackground})`,
            backgroundRepeat: "no-repeat",
          }}
          fluid
        >
          <Card.Title className="p-4" style={{ color: "blue" }} as="h3">
            OUR GOAL
          </Card.Title>
          <Card.Body className="transparent-card" border="0" as="h4">
            <ListGroup
              variant="flush"
              className="transparent-card"
              border="0"
              as="ul"
            >
              <ListGroup.Item className="transparent-card" as="li">
                - Adapting/mentoring/scholarship program for poor & single parent student
              </ListGroup.Item>
              <ListGroup.Item className="transparent-card" as="li">
                - Conducting health checkup & health camp in rural area.
              </ListGroup.Item>
              <ListGroup.Item className="transparent-card" as="li">
                - Skill development programs of rural youths.
              </ListGroup.Item>
               <ListGroup.Item className="transparent-card" as="li">
                - Help poor people accessing premium online digital services
              </ListGroup.Item>
              <ListGroup.Item className="transparent-card" as="li">
                - Help connecting community people by providing FREE websites
              </ListGroup.Item>
              <ListGroup.Item className="transparent-card" as="li">
                - Help bridging marriage match between Rich & Poor.
              </ListGroup.Item>
              <ListGroup.Item className="transparent-card" as="li">
                - Help poor in conducting mass marriage ceremony.
              </ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>






{/* Heath camp */}
<Container style={{ backgroundColor: "darkgray"}} fluid className="p-0">
  <Row>
    {/* Image Column */}
    <Col xs={12} md={6} className="d-flex justify-content-center mb-4 mb-md-0">
      <img
       src={healthcamp}
        className="rounded"
        alt="Matrimony image"
        style={{ width: "100%", height: "auto" }} 
      />
    </Col>

    {/* Second Column (Text Content) */}
    <Col md={4} xs={12}>
      <Card border="0" className="transparent-card">
        <Card.Title
          className="p-4"
          style={{ color: "#FFF", fontFamily: "Jura" }}
          as="h2"
        >
          <span style={{ color: "red" }}>FREE Health Camps</span> HELP in rural areas
        </Card.Title>
        <Card.Body className="transparent-card" as="h6">
          <ListGroup variant="flush" className="transparent-card" as="ul">
            <ListGroup.Item className="transparent-card" as="li">
              Reputed Doctors & medical specialists will be visiting rural areas to HELP people in 
              <span style={{ color: "red" }}> FREE Health Camps</span>.
            </ListGroup.Item>
            <ListGroup.Item className="transparent-card" as="li">
              FREE medical tests & consultation at people's own place. No need to travel to cities, saving travel & accommodation costs.
            </ListGroup.Item>
            <ListGroup.Item className="transparent-card" as="li">
              Doctor's assured appointment booking & reasonable rates for further treatment in reputed hospitals.
            </ListGroup.Item>
            <ListGroup.Item className="transparent-card" as="li">
            <Link to="/donate">
                <Button variant="danger"> Donate </Button>
              </Link>
            </ListGroup.Item>
          </ListGroup>
        </Card.Body>
      </Card>
    </Col>
  </Row>
</Container>

      {/* Matrimony section */}
<Container style={{ backgroundColor: "#cbc6c6"}} fluid className="p-0">
  <Row>
    {/* Image Column */}
    <Col xs={12} md={6} className="d-flex justify-content-center mb-4 mb-md-0">
      <img
        src={marrImage}
        className="rounded"
        alt="Matrimony image"
        style={{ width: "100%", height: "auto" }} // Make the image responsive
      />
    </Col>

    {/* Card Column */}
    <Col xs={12} md={6}>
      <Card border="0" fluid className="transparent-card">
        <Card.Title
          className="p-4"
          style={{ color: "grey", fontFamily: "Jura" }}
          as="h2"
        >
          Helping Poor in <span style={{ color: "red" }}> Marriage </span>
        </Card.Title>
        <Card.Body className="transparent-card" as="h6">
          <ListGroup variant="flush" className="transparent-card" as="ul">
            <ListGroup.Item className="transparent-card" as="li">
              We help poor Brides/Grooms finding their matches by
              <span style={{ color: "red" }}> Sponsoring </span> PREMIUM ACCOUNT charges 100%. Making it
              absolutely FREE.
            </ListGroup.Item>
            <ListGroup.Item className="transparent-card" as="li">
              AND partly sponsoring PREMIUM ACCOUNT charges for all others
              for encouragement. websites.
            </ListGroup.Item>
            <ListGroup.Item className="transparent-card" as="li">
              Also conduct mass Bride/Groom Mela, matrimony selection
              events in association with different communities.
            </ListGroup.Item>
            <ListGroup.Item className="transparent-card" as="li">
            <Link to="/donate">
                <Button variant="danger"> Donate </Button>
              </Link>
            </ListGroup.Item>
          </ListGroup>
        </Card.Body>
      </Card>
    </Col>
  </Row>
</Container>

    </>
  );
}
