import React from "react";
import { Card} from "react-bootstrap";
import ankolacamp from "../img/ankolacamp.png"
import ankolacampeng from "../img/ankolacampEng.png"
import "../App.css";


export default function eventPage() {
  return (
    <>
      <Card
        className="text-center"
        border="0"
        fluid
        style={{backgroundColor: "gray",padding: "10px" }}
      >
            <h1 style={{ color: "#FFF", padding: "15px", textShadow: "2px 2px 4px #000000" }}>Upcoming Events  </h1>
        <img
          src={ankolacamp}
          className="rounded"
          alt="Camp image"
          style={{ width: "100%", height: "auto" }}
        />
      </Card>
      <Card
        className="text-center"
        border="0"
        fluid
        style={{backgroundColor: "gray",padding: "10px" }}
      >
            
        <img
          src={ankolacampeng}
          className="rounded"
          alt="Camp image"
          style={{ width: "100%", height: "auto" }}
        />
      </Card>
    </>
  );
}
