import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Button from "react-bootstrap/Button";
import logo from "../img/Logo.png";
import { Link } from "react-router-dom";

function Header() {
  const [isNavOpen, setIsNavOpen] = useState(false);

  // Function to toggle the navbar collapse
  const handleNavToggle = () => {
    setIsNavOpen(!isNavOpen);
  };

  // Function to close navbar after selecting a link
  const handleLinkClick = () => {
    setIsNavOpen(false); // Close the navbar when any link is clicked
  };

  return (
    <Navbar
      collapseOnSelect // Ensures the navbar collapses on select
      expand="lg"
      className="bg-body-tertiary"
      bg="dark"
      data-bs-theme="dark"
      fixed="top"
      position="sticky" 
      style={{ minHeight: "96px" }}
 >
      <Container className="d-flex justify-content-between align-items-center">
        {/* Navbar.Brand: Make the logo image responsive */}
        <Navbar.Brand as={Link} to="/" className="d-flex align-items-center">
          <img
            src={logo}
            alt="Logo"
            className="d-inline-block align-middle img-fluid"
            style={{ maxWidth: "120px", height: "auto" }} // Adjust max-width for responsiveness
          />
          <span className="ms-2 text-white">Keshav Foundation</span>
        </Navbar.Brand>

        {/* Navbar Toggle for mobile */}
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={handleNavToggle} // Toggle the navbar when clicked
        />

        {/* Navbar Links */}
        <Navbar.Collapse id="responsive-navbar-nav" in={isNavOpen}>
          <Nav className="me-auto"></Nav>
          <Nav>
            <Nav.Link
              as={Link}
              to="/AboutUs"
              onClick={handleLinkClick} // Close navbar on click
            >
              About Us
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/Contact"
              onClick={handleLinkClick} // Close navbar on click
            >
              Contact Us
            </Nav.Link>
            <Nav.Link
              as={Link}
              to="/Donate"
              onClick={handleLinkClick} // Close navbar on click
            >
              <Button variant="danger">Donate</Button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Header;
