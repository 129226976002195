import "./App.css";
import React from "react";
import "bootstrap/dist/css/bootstrap.css";
import Header from "./componet/header";
import Footer from './componet/footer';
import FrontPage from "./pages/froentPage"
import Contact from "./pages/contact";
import AboutUs from './pages/aboutUs';
import Donate from "./pages/donate";
import Soon from "./componet/Soon";
import Event from "./pages/event";
import { Container } from 'react-bootstrap';
import { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';

function App() {
  return (
    <Router>
      <Header />
      <Container fluid style={{ marginTop: '96px', backgroundColor: 'gray' }}>
        <Routes>
          <Route path="/" element={<FrontPage />} />
          <Route path="/AboutUs" element={<AboutUs />} />
          <Route path="/Donate" element={<Donate />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/Event" element={<Event />} />
          <Route path="/comingSoon" element={<Soon />} />

          {/* Catch-all route for invalid paths */}
          <Route path="*" element={<RedirectToHome />} />
        </Routes>
      </Container>
      <Footer />
    </Router>
  );
}

function RedirectToHome() {
  const navigate = useNavigate(); // Only used inside the Router context

  useEffect(() => {
    // Redirect to home page if the route is invalid
    navigate('/');
  }, [navigate]);

  return <div>Redirecting...</div>;
}

export default App;
